import React           from 'react';
import { Divider }     from '../../components/divider/divider';
import { Logo }        from '../../components/logo/logo.component';
import { Wrapper }     from '../../components/wrapper/wrapper.component';
import { cn }          from '../../util/bem';
import { sizesPixels } from '../../util/global-props';

type PropsType = {
  onClick?: () => void;
  line?: boolean;
}
const DefaultProps = { line: true };

const className = cn('top-bar');

export const TopBar = (props: PropsType) => (
    <Wrapper margin="lg" style={ { marginBottom: sizesPixels.lg } }>
        <Wrapper
            className={ className() }
            frame="solid-white"
            fullWidth
            padding={ { bottom: 'md' } }
            style={ { marginTop: '-8px' } }
            xsm={ { margin: { top: 'off' } } }
        >
            <Wrapper onClick={ props.onClick } style={ { marginLeft: '-8px' } }>
                <Logo />
            </Wrapper>
        </Wrapper>
        <Divider transparent={ !props.line } />
    </Wrapper>
);

TopBar.defaultProps = DefaultProps;
