export type TextColorType =
    |'white'
    |'secondary'
    |'primary'
    |'dark'
    |'dark-5'
    |'dark-3'
    |'grey-3'
    |'grey-6'
    |'key'
    |'mint'
    |'green'
    |'red'
    |'blue'
    |'violet'
export const textColors: Record<TextColorType, TextColorType> = {
    white:     'white',
    secondary: 'secondary',
    primary:   'primary',
    dark:      'dark',
    mint:      'mint',
    'dark-5':  'dark-5',
    'dark-3':  'dark-3',
    'grey-3':  'grey-3',
    'grey-6':  'grey-6',
    key:       'key',
    green:     'green',
    red:       'red',
    blue:      'blue',
    violet:    'violet'
};
export const iconColors: Record<string, string | undefined> = {
    default:  undefined,
    none:     'none',
    dark:     'dark',
    'dark-5': 'dark-5',
    grey:     'grey',
    white:    'white',
    key:      'key',
    green:    'green',
    red:      'red'
};
export type IconColorType = 'none' | 'dark' | 'dark-5' | 'grey' | 'white' | 'key' | 'green' | 'red';

export type SizeType =
    | 'off'
    | 'xxs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl';
export type SizeTypeDirection = {
    top?: SizeType;
    bottom?: SizeType;
    right?: SizeType;
    left?: SizeType;
};

export const sizes: Record<SizeType, SizeType> = {
    off: 'off',
    xxs: 'xxs',
    xs:  'xs',
    sm:  'sm',
    md:  'md',
    lg:  'lg',
    xl:  'xl',
    xxl: 'xxl'
};
export const sizesPixels: Record<SizeType, string> = {
    off: '0px',
    xxs: '4px',
    xs:  '8px',
    sm:  '16px',
    md:  '32px',
    lg:  '40px',
    xl:  '48px',
    xxl: '56px'
};
export type WeightType = 'bold'|'regular';
export const Weights: Record<WeightType, WeightType> = {
    regular: 'regular',
    bold:    'bold'
};

export type FormSizesType = 'xs'|'sm' | 'md' | 'lg' | 'xl';
export const formSizes: Record<string, string | undefined> = {
    default: undefined,
    xs:      'xs',
    sm:      'sm',
    md:      'md',
    lg:      'lg',
    xl:      'xl'
};
export const fromSizesMap = Object.keys(formSizes) as NonNullable<FormSizesType>[];
export const formSizesPixels: Record<string, string | undefined> = {
    default: 'lg - 48px',
    xs:      '24px',
    sm:      '32px',
    md:      '40px',
    lg:      '48px',
    xl:      '56px'
};
export type RadiusSizesType = 'xs' | 'sm' | 'md' | 'lg';
export const radiusSizes: Record<string, string | undefined> = {
    default: undefined,
    xs:      'xs',
    sm:      'sm',
    md:      'md',
    lg:      'lg'
};
export const radiusSizesPixels: Record<string, string | undefined> = {
    default: '0px',
    xs:      '2px',
    sm:      '4px',
    md:      '6px',
    lg:      '8px'
};

export type HidePropsType = [ '' | 'xsm' | 'sm' | 'md' | 'lg' | 'xlg', '' | 'xsm' | 'sm' | 'md' | 'lg' | 'xlg' | undefined]
