import React, { useState } from 'react';
import { navigate }        from 'gatsby';
import { Button }          from '../../lib/components/button/button';
import { Divider }         from '../../lib/components/divider/divider';
import { Image }           from '../../lib/components/image/image';
import { Paragraph }       from '../../lib/components/paragraph/paragraph';
import { Wrapper }         from '../../lib/components/wrapper/wrapper.component';

export type PublicationType =
  | ''
  |'other'
  | 'pl' // Psi Life
  | 'is' // Ink Stains
  | 'ar' // Annual Report
  | 'hb' // Handbooks
  ;

export type LinkType = {
    title: string;
    link: string;
} | 'latest';
export type CategoryType ={
    key: PublicationType;
    description?: string;
    title: string;
    color?: string;
    primaryLink?: LinkType;
    secondaryLink?: LinkType;
}
export type PublicationItem = {
  type: PublicationType;
  title: string;
  link: string;
  src: string;
}

export const categories: CategoryType[] = [
    { key: '', title: 'All' },
    {
        key:         'hb',
        title:       'Handbooks',
        color:       '#85A8EB',
        description: 'A magazine is a periodical publication which is printed in gloss-coated and matte paper. Magazines are generally published on a regular schedule and contain a variety of data. ',
        primaryLink: { title: 'Discover', link: '/handbook/' }
    },
    {
        key:         'ar',
        title:       'Annual Report',
        color:       '#9BA9F0',
        description: 'A magazine is a periodical publication which is printed in gloss-coated and matte paper. Magazines are generally published on a regular schedule and contain a variety of data. ',
        primaryLink: 'latest'
    },
    {
        key:           'pl',
        title:         'PSI Life',
        color:         '#93CCDD',
        description:   'A magazine is a periodical publication which is printed in gloss-coated and matte paper. Magazines are generally published on a regular schedule and contain a variety of data. ',
        primaryLink:   'latest',
        secondaryLink: { title: 'All issues', link: '/psi-life/' }
    },
    {
        key:           'is',
        title:         'Ink Stains',
        color:         '#8DD5CD',
        description:   'A magazine is a periodical publication which is printed in gloss-coated and matte paper. Magazines are generally published on a regular schedule and contain a variety of data. ',
        primaryLink:   'latest',
        secondaryLink: { title: 'All issues', link: '/ink-stains/' }
    }

];

export const publications: PublicationItem[] = [
    { type: 'pl', title: 'Fall 2021', link: 'https://fall-2021-life.psi.kiev.ua/', src: 'main_psi_life_20' },
    { type: 'ar', title: 'Annual Report 2021', link: '/annual-report/2021', src: 'main_annual_report_2021' },
    { type: 'hb', title: 'Parent Student Handbook 2021', link: '/handbook/parent-student/', src: 'main_handbook_parent' },
    { type: 'hb', title: 'Primary Programme Guide 2021', link: '/handbook/primary/', src: 'main_handbook_primary' },
    { type: 'hb', title: 'Secondary Programme Guide 2021', link: '/handbook/secondary/', src: 'main_handbook_secondary' },
    { type: 'hb', title: 'Child Protection Handbook 2022', link: 'https://drive.google.com/file/d/1U_ug1uLEtrkz0L7gjKbL5HU8kCLu2-d0/view', src: 'main_child_protection_2022' },
    { type: 'is', title: 'Ink stains 2021', link: '/ink-stains/2021', src: 'main_ink_stains_2021' },
    { type: 'pl', title: 'Spring 2021', link: 'https://readymag.com/u35683460/2731216/', src: 'main_psi_life_19' },

    { type: 'ar', title: 'Annual Report 2020', link: '/annual-report/2020', src: 'main_annual_report_2020' },
    { type: 'pl', title: 'Winter 2020', link: 'http://winter-2020-life.psi.kiev.ua/', src: 'main_psi_life_18' },
    { type: 'pl', title: 'Fall 2020', link: 'https://fall-2020-life.psi.kiev.ua/', src: 'main_psi_life_17' },
    { type: 'is', title: 'Ink stains 2020', link: '/ink-stains/2020', src: 'main_ink_stains_2020' },
    { type: 'pl', title: 'Spring 2020', link: 'https://life.psi.kiev.ua/', src: 'main_psi_life_16' },

    { type: 'pl', title: 'Winter 2019', link: 'https://issuu.com/psipsi/docs/psi_life_2019_winter__5_', src: 'main_psi_life_15' },
    { type: 'pl', title: 'Fall 2019', link: 'https://issuu.com/psipsi/docs/psi_life_2019_fall__2_', src: 'main_psi_life_14' },
    { type: 'is', title: 'Ink stains 2019', link: 'https://issuu.com/psipsi/docs/ink_2019', src: 'main_ink_stains_2019' },
    { type: 'pl', title: 'Spring 2019', link: 'https://issuu.com/psipsi/docs/psi_life_2019_spring_final', src: 'main_psi_life_13' },

    { type: 'pl', title: 'Winter 2018', link: 'https://issuu.com/psipsi/docs/09_psi_life_winter_2018-2019_for_pr', src: 'main_psi_life_12' },
    { type: 'pl', title: 'Fall 2018', link: 'https://issuu.com/psipsi/docs/cover1_psi_life_fall_2018_print', src: 'main_psi_life_11' },
    { type: 'is', title: 'Ink stains 2018', link: 'https://issuu.com/psipsi/docs/inkstain_2018_prev', src: 'main_ink_stains_2018' },
    { type: 'pl', title: 'Spring 2018', link: 'https://issuu.com/psipsi/docs/09_psi_life_spring_2018_1', src: 'main_psi_life_10' },

    { type: 'pl', title: 'Winter 2017', link: 'https://issuu.com/psipsi/docs/08_psi_life_winter_2017', src: 'main_psi_life_09' },
    { type: 'pl', title: 'Fall 2017', link: 'https://issuu.com/psipsi/docs/08_psi_life_fall_2017_all', src: 'main_psi_life_08' },
    { type: 'is', title: 'Ink stains 2017', link: 'https://issuu.com/psipsi/docs/inkstain_mag_2017_web', src: 'main_ink_stains_2017' },
    { type: 'pl', title: 'Spring 2017', link: 'https://issuu.com/psipsi/docs/07_psi_life_spring_2017', src: 'main_psi_life_07' },

    { type: 'pl', title: 'Winter 2016', link: 'https://issuu.com/psipsi/docs/06_psi_life_winter_2016_2017_print', src: 'main_psi_life_06' },
    { type: 'pl', title: 'Fall 2016', link: 'https://issuu.com/psipsi/docs/05_psi_life_fall_2016_print', src: 'main_psi_life_05' },
    { type: 'is', title: 'Ink stains 2016', link: 'https://issuu.com/psipsi/docs/inkstain_mag_final', src: 'main_ink_stains_2016' },
    { type: 'pl', title: 'Spring 2016', link: 'https://issuu.com/psipsi/docs/psi_life_spring_2016_print', src: 'main_psi_life_04' },

    { type: 'pl', title: 'Winter 2015', link: 'https://issuu.com/psipsi/docs/psi_life_winter_2015', src: 'main_psi_life_03' },
    { type: 'pl', title: 'Fall 2015', link: 'https://issuu.com/psipsi/docs/psi_life_04_print__3_', src: 'main_psi_life_02' },
    { type: 'is', title: 'Ink stains 2015', link: 'https://issuu.com/psipsi/docs/inkstains', src: 'main_ink_stains_2015' },
    { type: 'pl', title: 'Spring 2015', link: 'https://issuu.com/psipsi/docs/psi_magazine_print__4_', src: 'main_psi_life_01' }
];

const Item = (p: {src: string; title: string; link: string; blank?: boolean}) => {
    const handleClick = () => {
        if (p.link.includes('http')) {
            window.open(p.link, '_blank');
        } else {
            navigate(p.link);
        }
    };
    return (
        <Wrapper padding="lg" xsm={ { padding: 'xs' } }>
            <Image
                srcName={ p.src }
                margin="xs"
                // onClick={ () => (!p.blank ? navigate(p.link) : window.open(p.link, '_blank')) }
                onClick={ handleClick }
                radius="sm"
            />
            <Paragraph size="sm">
                { ' ' }
                { p.title }
            </Paragraph>
        </Wrapper>
    );
};

export const Publications = (props: {type?: PublicationType}) => {
    const [ type, setType ] = useState<PublicationType>(props.type || '');
    const meta = categories.find((item) => item.key === type);
    return (
        <Wrapper padding="xs" style={ { minWidth: '100%' } }>
            <Wrapper
                frame="solid-dark"
                radius="lg"
                style={ { backgroundColor: meta ? meta.color : undefined, minWidth: '100%' } }

            >
                <Divider transparent margin="md" />
                { !props.type && (
                    <Wrapper row align="center" frame="solid-dark" scrollHorizontal style={ { backgroundColor: meta ? meta.color : undefined } }>
                        <Wrapper
                            row
                            padding="sm"
                            display="flex"
                            xsm={ { display: 'none' } }
                        >
                            { categories.map((item) => (
                                <Button
                                    key={ item.key }
                                    viewType={ item.key === type ? 'primary' : 'dark' }
                                    onClick={ () => setType(item.key) }
                                    size="sm"
                                    margin="sm"
                                    styles={ { whiteSpace: 'nowrap' } }
                                >
                                    { item.title }
                                </Button>
                            )) }
                        </Wrapper>
                    </Wrapper>
                ) }
                <Wrapper
                    columns={ 4 }
                    row
                    padding="xxl"
                    md={ { columns: 3, padding: 'sm' } }
                    sm={ { columns: 2, padding: 'sm' } }
                >

                    { publications.map((item, index) => {
                        if (type && type !== item.type) { return; }
                        return (
                            <Item key={ index } src={ item.src } title={ item.title } link={ item.link } />
                        );
                    }) }
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};
