import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject }             from 'gatsby-image';

type ImageOutputType = FluidObject & {
    originalImg: string;
}

type ReducedImages = Record<string, FluidObject>

// let reducedImagesCache: ReducedImages | undefined;

export const useGetImgSet = <T extends string, U = { [K in T]: ImageOutputType }>(names: T[]): U => {
    // if (!reducedImagesCache) {
    const query = useStaticQuery(graphql`
        query ImageQuery {
            allImageSharp {
                edges {
                    node {
                        fluid(quality: 70, pngQuality: 70, maxWidth: 1600) {
                            srcSet
                            src
                            sizes
                            presentationHeight
                            presentationWidth
                            originalName
                            originalImg
                            aspectRatio
                        }
                    }
                }
            }
        }`);

    const reducedImagesCache = query.allImageSharp.edges.reduce((carry: Record<string, FluidObject>, item: any) => {
        const itemKey = item.node.fluid.originalName.split('.')[ 0 ];
        return {
            ...carry,
            [ itemKey ]: item.node.fluid
        };
    }, {});
    // }

    return names.reduce((c: U, i) => ({
        ...c,
        [ i ]: reducedImagesCache && reducedImagesCache[ i ] ? reducedImagesCache[ i ] : undefined
    }), {} as U);
};
