import React, { FC, memo }               from 'react';
import { cn }                            from '../../util/bem';
import { dataFunction }                  from '../../util/data-function';
import { FormSizesType, TextColorType }  from '../../util/global-props';
import { universalData, UniversalProps } from '../../util/universalProps';
import './button.scss';

export type ButtonPropsType = UniversalProps<{
  children?: React.ReactNode;
  before?: React.ReactNode;
  after?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';

  align?: 'left' | 'center' | 'right';
  viewType?: 'primary' | 'primary-green' | 'primary-white' | 'secondary' | 'dark' | 'transparent' | 'alert';
  size?: FormSizesType;
  color?: TextColorType;
  bold?: boolean;
  uppercase?: boolean;

  loading?: boolean;

  rounded?: boolean;
  disabled?: boolean;

}, HTMLButtonElement>

const className = cn('button');

export const Button: FC<ButtonPropsType> = memo((props) => {
    const getContent = () => (
        <span className={ className('content') }>
            { props.before }
            { props.children }
            { props.after }
        </span>
    );

    return (
        <button
            className={ className(null, null, props.className) }
            type={ props.type || 'button' }
            { ...universalData(props) }
            { ...dataFunction({
                size:      props.size,
                align:     props.align,
                type:      props.viewType,
                rounded:   props.rounded,
                noContent: !props.children,
                color:     props.color,
                bold:      props.bold,
                uppercase: props.uppercase
            }) }
        >
            { getContent() }

        </button>
    );
});

Button.defaultProps = {
    type:     'button',
    size:     'xl',
    viewType: 'primary'
};
