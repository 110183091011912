import React                         from 'react';
import Img, { FluidObject }          from 'gatsby-image';
import { useGetImgSet }              from '../../util/get-images';
import { Wrapper, WrapperPropsType } from '../wrapper/wrapper.component';

type ImageStyleProps = {
    src?: FluidObject;
    srcName?: string;
    alt?: string;
    imgStyle?: React.CSSProperties;
    proportion?: number;
} & WrapperPropsType;

export type ImagePropsType = ImageStyleProps;

export const Image = ({
    src, alt, imgStyle, srcName, ...props
}: ImagePropsType) => {
    const img = useGetImgSet([ srcName ]);
    return (
        <Wrapper
            overflow="hidden"
            { ...props }
            style={ { paddingBottom: `${ props.proportion }%`, ...props.style } }
        >
            <Img
                fluid={ img[ srcName ] || src }
                alt={ alt }
                imgStyle={ imgStyle }
                style={ {
                    display: 'flex',
                    width:   '100%',
                    ...props.proportion && {
                        position: 'absolute',
                        top:      0,
                        left:     0,
                        width:    '100%',
                        height:   '100%'
                    },
                    ...imgStyle
                } }
            />
        </Wrapper>
    );
};
Image.defaultProps = { fullWidth: true };
