import React, { useRef, useState }   from 'react';
import { Wrapper, WrapperPropsType } from 'lib/components/wrapper/wrapper.component';
import { generateColorsVars }        from '../../styles/color';
import { cn }                        from '../../util/bem';
import './site-layout.scss';

type PropsType = {
  children: React.ReactNode;
} & WrapperPropsType;
const className = cn('site-layout');

export const ScrollPosition = React.createContext(0);

export const SiteLayout = (props: PropsType) => {
    const ref = useRef(null);
    const [ scroll, setScroll ] = useState(0);
    const onScroll = (e: any) => {
        setScroll(e.target.scrollTop);
    };

    return (
        <Wrapper
            ref={ ref }
            onScroll={ onScroll }
            className={ className() }
            padding="xxl"
            align="left"
            styles={ { ...generateColorsVars(), minWidth: '100vw' } }
            sm={ { padding: 'sm' } }
            { ...props }
        >
            <ScrollPosition.Provider value={ scroll }>
                { props.children }
            </ScrollPosition.Provider>
        </Wrapper>
    );
};
