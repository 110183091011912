import React                                                      from 'react';
import { dataFunction }                                           from './data-function';
import { HideType, RadiusSizesType, SizeType, SizeTypeDirection } from './global-props';

type styleProps = {
  padding?: SizeType | SizeTypeDirection;
  margin?: SizeType | SizeTypeDirection;
  flex?: number | string;
  maxWidth?: string | number;
  maxHeight?: string;
  fullWidth?: boolean; // Width 100%
  radius?: RadiusSizesType;
}

export type UniversalProps<T, N = HTMLDivElement> = T & styleProps & {
  className?: string;

  xsm?: Partial<T & styleProps>;
  sm?: Partial<T & styleProps>;
  md?: Partial<T & styleProps>;
  lg?: Partial<T & styleProps>;
  hover?: Partial<T & styleProps>;

  data?: Record<string, string>;
  style?: React.CSSProperties;
  styles?: React.CSSProperties;
  hide?: HideType;

  ref?: React.Ref<N>;
  onClick?: React.EventHandler<React.MouseEvent<N, PointerEvent>>;
  onMouseDown?: React.EventHandler<React.MouseEvent<N, PointerEvent>>;
  onFocus?: React.EventHandler<React.FocusEvent<N>>;
  onMouseLeave?: React.EventHandler<React.MouseEvent<N>>;
  onMouseEnter?: React.EventHandler<React.MouseEvent<N>>;
}

export const universalData = ({
    padding, margin, flex, maxWidth, maxHeight, fullWidth, radius,
    xsm, sm, md, lg, hover,
    data, style, styles, hide,
    ref, onClick, onMouseDown, onMouseLeave, onMouseEnter
}: any) => {
    const out = {
        ref,
        onClick,
        onMouseDown,
        onMouseLeave,
        onMouseEnter,
        ...dataFunction({
            fullWidth,
            flex,
            margin,
            padding,
            xsm,
            sm,
            md,
            lg,
            radius,
            hide: hide && `${ hide[ 0 ] ? hide[ 0 ] : 'zero' }-${ hide[ 1 ] ? hide[ 1 ] : 'infinite' }`,
            hover
        }),
        ...data || undefined,
        style: {
            maxWidth,
            maxHeight,
            ...style,
            ...styles
        }
    };
    // if (style) {
    //     console.log(out);
    // }
    return out;
};
